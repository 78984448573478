import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import { AuthProvider } from "./auth/AuthContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<Suspense fallback={<Loader />}>
		<HashRouter>
			<AuthProvider>
				<App />
			</AuthProvider>
		</HashRouter>
	</Suspense>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics ENDPOINT. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
