import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const Login = lazy(() => import("../views/Login.js"));
const Users = lazy(() => import("../views/Users.js"));
const UsersTransactions = lazy(() => import("../views/UsersTransactions.js"));
const Matches = lazy(() => import("../views/Matches.js"));
const Litiges = lazy(() => import("../views/Litiges.js"));
const Games = lazy(() => import("../views/Games.js"));
const Stats = lazy(() => import("../views/Stats.js"));
const Transactions = lazy(() => import("../views/Transactions.js"));
const UserDetails = lazy(() => import("../views/UserDetails.js"));
const Feedbacks = lazy(() => import("../views/Feedbacks.js"));
// const Logout = lazy(() => import("../views/Logout.js"));

/*****Routes******/

const ThemeRoutes = [
	{
		path: "/",
		element: <FullLayout />,
		children: [
			{ path: "/", element: <Navigate to="/tableau-de-bord" /> },
			{ path: "/login", exact: true, element: <Login /> },
			{ path: "/tableau-de-bord", exact: true, element: <Starter /> },
			{ path: "/utilisateurs", element: <Users /> },
			{ path: "/usersTransactions", element: <UsersTransactions /> },
			{ path: "/matches", element: <Matches /> },
			{ path: "/litiges", element: <Litiges /> },
			{ path: "/les-jeux", element: <Games /> },
			{ path: "/statistiques", element: <Stats /> },
			{ path: "/transactions", element: <Transactions /> },
			{ path: "/users/:id", element: <UserDetails /> },
			{ path: "/feedbacks", element: <Feedbacks /> },
		],
	},
];

export default ThemeRoutes;
